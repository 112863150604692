@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Marlin Geo"; /* Choose a font family name */
  src: url("../src/assets/fonts/MarlinGeo-Regular.ttf") format("truetype");
}

body {
  color: white;
  font-family: "Marlin Geo", sans-serif;
  overflow-x: hidden;
}

html {
  background-color: #ffe6d8;
  overflow-x: hidden;
}

.nav-text {
  color: #3d4754;
}

.login-text {
  color: #993500;
}
.button-color {
  background: rgba(255, 142, 111, 0.75);
}
.login-card {
  background-color: rgba(255, 255, 255, 0.3);
}
.login-card-mobile {
  background-color: #ffcfc2;
}


.product-card {
  border-radius: 25px;
  background: #ffddca;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.product-card:hover {
  transform: scale(1.05); /* Increase size on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add a box shadow on hover */
}

.comparisonCard {
  border: 2px solid rgba(255, 134, 102, 0.5);
}

.comparisonRankingCard {
  border-radius: 20px;
  border: 3px solid #bc7869;
  background: rgba(255, 113, 52, 0.25);
}

.submitbg {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
}

.required input::after {
  content: "*";
  color: red;
}

.shadowCard {
  box-shadow: 0px 15px rgba(255, 178, 154, 0.75),
    0px 30px rgba(255, 178, 154, 0.50196);
}
.shadowCardHome {
  box-shadow: 0px 9px rgba(255, 178, 154, 0.75),
    0px 18px rgba(255, 178, 154, 0.50196);
}
.shadowCardCarousel {
  box-shadow: 0px 7px rgba(255, 178, 154, 0.75),
    0px 15px rgba(255, 178, 154, 0.50196);
}
